import React, { ReactElement } from "react"
import { AuthorEntity } from "../../entities/AuthorEntity"
import { As } from "../As/As"

import { Article } from "./Article"

type ArticleEntity = {
  id: string
  title: string
  image?: any
  description: string
  category: string
  url: string
  date?: string
  reading?: number
  author?: AuthorEntity
}

type Props = {
  title: { as?: string; value: ReactElement }
  description: ReactElement
  articles: Array<ArticleEntity>
}

const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const Blog: React.FC<Props> = props => {
  return (
    <div className="relative px-4 pt-16 pb-20 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative mx-auto max-w-7xl">
        <div className="">
          <As
            as={props.title.as || "h6"}
            className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
          >
            {props.title.value}
          </As>
          <p className="max-w-2xl mt-3 text-xl text-gray-500 sm:mt-4">
            {props.description}
          </p>
        </div>
        <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
          {props.articles.map(article => (
            <Article {...article} key={article.url} />
          ))}
        </div>
      </div>
    </div>
  )
}
