import { Link } from "gatsby"
import React, { ReactElement } from "react"
import Img from "gatsby-image"
import { AuthorEntity } from "../../entities/AuthorEntity"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

type ArticleEntity = {
  id: string
  title: string
  image: any
  description: string
  category: string
  url: string
  thumb: any
  date?: string
  reading?: number
  author?: AuthorEntity
}

const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const Article: React.FC<ArticleEntity> = props => {
  const illustration = props.image && getImage(props.image)

  return (
    <div
      key={props.url}
      className="flex flex-col overflow-hidden transition-shadow duration-300 rounded-lg shadow-lg hover:shadow-xl"
    >
      {illustration && (
        <GatsbyImage
          image={illustration}
          alt={props.title}
          title={props.title}
          className="h-80"
        />
      )}

      <div className="flex flex-col justify-between flex-1 p-6 bg-white">
        <div className="flex-1">
          {props.category && (
            <p className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
              {capitalize(props.category)}
            </p>
          )}

          <Link to={props.url} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{props.title}</p>
            <p className="mt-3 text-base text-gray-700">{props.description}</p>
          </Link>
        </div>
        {props.author && (
          <div className="flex items-center mt-6">
            <div className="flex-shrink-0">
              <span>
                <span className="sr-only">{props.author.name}</span>
                <Img
                  className="border border-gray-200 rounded-full"
                  title={"Image de profile de " + props.author.name}
                  alt={"Image de profile de " + props.author.name}
                  fixed={props.author.thumb.childImageSharp.fixed}
                />
              </span>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                <span>{props.author.name}</span>
              </p>
              <div className="flex space-x-1 text-sm text-gray-500">
                <time dateTime={props.date}>{props.date}</time>
                <span aria-hidden="true">&middot;</span>
                <span>{props.reading} minutes de lecture</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
