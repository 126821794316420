import React from "react"
import { Link, navigate } from "gatsby"

export const SeoLink: React.FC<{
  className?: string
  state?: any
  to: string
  isAsync?: boolean
}> = props => {
  if (props.isAsync)
    return (
      <span
        onClick={() => navigate(props.to, { state: props.state || {} })}
        className={(props.className || "") + " cursor-pointer"}
        children={props.children}
      ></span>
    )

  return (
    <Link
      to={props.to}
      state={props.state}
      className={props.className}
      children={props.children}
    />
  )
}
