import React, { useEffect, useState } from "react"
import { Transition } from "@headlessui/react"
import { Link, graphql, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import Logo from "../../assets/logo/logo.svg"

import { Drawer } from "../Drawer/Drawer"

import { SeoLink } from "../SeoLink/SeoLink"

const Item = ({ title, to, description, icon }) => (
  <Link
    to={to}
    className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
  >
    <svg
      className="flex-shrink-0 w-6 h-6 text-gray-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      {icon}
    </svg>
    <div className="pl-4">
      <p className="text-base font-medium text-gray-900">{title}</p>
      <p className="mt-1 text-sm text-gray-600">{description}</p>
    </div>
  </Link>
)

const query = graphql`
  query GetNavigationArticles {
    articles: allMdx(
      sort: { fields: [frontmatter___created_at], order: DESC }
      limit: 3
    ) {
      nodes {
        id
        url: parent {
          ... on File {
            relativeDirectory
          }
        }
        frontmatter {
          display__title
          seo__description
        }
      }
    }
  }
`

export type NavigationProps = {
  showLinksDirectlyForSeo?: boolean
}

export const Navigation: React.FC<NavigationProps> = ({
  showLinksDirectlyForSeo,
}) => {
  const [isBlogOpen, setBlogOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const fn = () => {
      if (isBlogOpen) setBlogOpen(false)
    }

    window.addEventListener("click", fn)

    return () => {
      window.removeEventListener("click", fn)
    }
  }, [isBlogOpen])

  return (
    <>
      <div className="absolute top-0 left-0 z-10 w-full">
        <div className="relative">
          <div className="px-4 mx-auto max-w-7xl md:px-6">
            <div className="flex items-center py-6 lg:space-x-10">
              <div className="flex">
                <SeoLink isAsync={!showLinksDirectlyForSeo} to="/">
                  <span className="sr-only">Le Temple du Haïku</span>
                  <img
                    className="w-auto h-8 md:h-12"
                    src={Logo}
                    alt="Logo du Temple"
                  />
                </SeoLink>
              </div>
              <div className="ml-auto -my-2 -mr-2 lg:hidden">
                <button
                  type="button"
                  onClick={() =>
                    navigate(window?.location.pathname + "#menu=open")
                  }
                  className="inline-flex items-center justify-center p-2 text-gray-600 rounded-md hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900"
                >
                  <span className="sr-only">Ouvrir le menu</span>

                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <nav className="hidden space-x-10 lg:flex">
                <SeoLink
                  isAsync={!showLinksDirectlyForSeo}
                  to="https://editeur.temple-du-haiku.fr"
                  className="text-base font-medium text-gray-600 hover:text-gray-900"
                >
                  Découvrir
                </SeoLink>
                <SeoLink
                  isAsync={!showLinksDirectlyForSeo}
                  to="https://editeur.temple-du-haiku.fr/ecrire/"
                  className="text-base font-medium text-gray-600 hover:text-gray-900"
                >
                  Écrire
                </SeoLink>
                <SeoLink
                  isAsync={false}
                  to="/poete-japonais/"
                  className="text-base font-medium text-gray-600 hover:text-gray-900"
                >
                  Les poètes japonais
                </SeoLink>
                <SeoLink
                  isAsync={false}
                  to="/exemples-de-haiku/"
                  className="text-base font-medium text-gray-600 hover:text-gray-900"
                >
                  Exemples de haïkus
                </SeoLink>

                <div className="relative">
                  <button
                    type="button"
                    onClick={() => setBlogOpen(true)}
                    className={`group rounded-md text-gray-600 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none ${
                      isBlogOpen && "text-gray-900"
                    }`}
                  >
                    <span>Articles</span>
                    <svg
                      className={`ml-2 h-5 w-5 transform ${
                        isBlogOpen && "rotate-180"
                      } transition duration-150`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <Transition
                    show={isBlogOpen}
                    enter="z-10 ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <div className="absolute z-10 w-screen max-w-md px-2 mt-3 transition transform -translate-x-1/2 left-1/2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative z-20 grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          <Item
                            to="/apprendre/"
                            title="Apprentissage du haïku"
                            description="Apprenez les bases de la conception des haïkus, dans la culture française et japonaise."
                            icon={
                              <>
                                <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                                <path
                                  fill="#fff"
                                  d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                                />
                              </>
                            }
                          />

                          <Item
                            to="/histoire/"
                            title="Histoire du haïku"
                            description="De l'époque Edo à de nos jours, traversons ensemble l'histoire du haïku."
                            icon={
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                              />
                            }
                          />

                          <Item
                            title="Recueil d'anciens haïkus"
                            description="Accédez au recueil des haïkus les plus populaire et les moins connus des maîtres de la pratique."
                            to="/recueils/"
                            icon={
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                              />
                            }
                          />
                        </div>

                        {/* <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                          <div>
                            <span className="text-sm font-medium tracking-wide text-gray-600 uppercase">
                              Les articles récents
                            </span>
                            <ul className="mt-4 space-y-4">
                              {articles.nodes.map(({ frontmatter, url }) => (
                                <li
                                  className="text-base truncate"
                                  key={url.relativeDirectory}
                                >
                                  <Link
                                    to={`/${url.relativeDirectory}/`}
                                    className="font-medium text-gray-900 transition-colors hover:text-green-600"
                                  >
                                    {frontmatter.display__title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* <div className="mt-5 text-sm">
                            <a
                              href="#"
                              className="font-medium text-green-600 hover:text-green-500"
                            >
                              {" "}
                              Voir tous les articles{" "}
                              <span aria-hidden="true">&rarr;</span>
                            </a>
                          </div> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </Transition>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <Drawer />

      </div>
    </>
  )
}
