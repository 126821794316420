import { Link } from "gatsby"
import React, { ReactElement } from "react"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { As } from "../As/As"
import Img from "gatsby-image"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

export const Breadcrumb: React.FC<{
  items: Array<{ label: string; url?: string }>
}> = ({ items }) => (
  <ol className="flex flex-wrap items-center">
    {[{ label: "Accueil", url: "/" }, ...items].map(
      ({ label, url }, index, array) => (
        <React.Fragment key={url}>
          {url && index < array.length - 1 && (
            <li className="breadcrumb__separator">
              <Link to={url} className="cursor-pointer hover:underline">
                {label}
              </Link>
            </li>
          )}

          {(!url || index === array.length - 1) && (
            <li className="text-gray-400">{label}</li>
          )}
        </React.Fragment>
      )
    )}
  </ol>
)

const Author: React.FC<{
  date?: { created_at?: string; updated_at?: string }
  image: ArticleEntity["author"]["thumb"]
  name: string
  reading?: number
}> = props => {
  return (
    <div className={`mt-4 lg:mt-8 flex items-center`}>
      <div className="flex-shrink-0">
        <div>
          <span className="sr-only">{props.name}</span>
          <Img
            className="rounded-full"
            fixed={props.image.childImageSharp.fixed}
            alt={`image de profil de ${props.name}`}
          />
        </div>
      </div>

      <div className="ml-3">
        <p className="font-medium text-gray-900">{props.name}</p>

        <div className="flex flex-wrap text-sm text-gray-400">
          {props.date.created_at === props.date.updated_at && (
            <time dateTime={props.date.created_at}>
              Publié le {props.date.created_at}
            </time>
          )}
          {props.date.created_at !== props.date.updated_at && (
            <time dateTime={props.date.updated_at}>
              Mis à jour le {props.date.updated_at}
            </time>
          )}
          {props.reading && (
            <>
              <span className="px-1">•</span>
              <span>Temps de lecture de {props.reading} minutes</span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export const Hero: React.FC<{
  title: { as?: string; value: ReactElement | string; raw?: boolean }
  description: { as?: string; value: ReactElement | string; raw?: boolean }
  theme: { as?: string; value: ReactElement }
  illustration?: IGatsbyImageData
  illustrationCredit?: string
  author?: ArticleEntity["author"]
  date?: { created_at?: string; updated_at?: string }
  reading?: number
}> = props => {
  return (
    <div className="relative pb-16">
      <main className="px-4 mx-auto mt-8 max-w-7xl md:mt-16 sm:px-6 lg:mt-24">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="pt-14 md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <As
              as={props.theme.as || "span"}
              className="block text-sm font-semibold tracking-wide text-gray-500 uppercase sm:text-base lg:text-sm xl:text-base"
            >
              {props.theme.value}
            </As>
            <As
              as={props.title.as || "h1"}
              {...(props.title.raw
                ? { dangerouslySetInnerHTML: { __html: props.title.value } }
                : { children: props.title.value })}
              className="block mt-1 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl xl:text-6xl"
            />
            <div className="mt-4 lg:hidden">
              {props.illustration && (
                <>
                  <GatsbyImage
                    className="object-contain object-center w-full rounded-lg md:h-full"
                    image={props.illustration}
                    alt="image d'illustration"
                    title="image d'illustration"
                  />
                  {props.illustrationCredit && (
                    <div className="mt-2 text-sm italic text-center text-gray-500">
                      {props.illustrationCredit}
                    </div>
                  )}
                </>
              )}
            </div>

            <As
              as={props.description.as || "p"}
              {...(props.description.raw
                ? {
                    dangerouslySetInnerHTML: {
                      __html: props.description.value,
                    },
                  }
                : { children: props.description.value })}
              className="mt-3 text-lg text-gray-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl"
            />
            {props.author && (
              <Author
                date={props.date}
                image={props.author.thumb}
                name={props.author.name}
                reading={props.reading}
              />
            )}
          </div>
          <div className="relative hidden col-span-6 lg:block max-w-none">
            {props.illustration && (
              <>
                <GatsbyImage
                  className="object-contain object-center rounded-lg"
                  image={props.illustration}
                  alt="image d'illustration"
                  title="image d'illustration"
                />

                {props.illustrationCredit && (
                  <div className="mt-2 text-sm italic text-center text-gray-500">
                    {props.illustrationCredit}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  )
}
